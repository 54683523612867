import headerImage from '../assets/images/event/event-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import LogoSlide from '../gallery-slides/LogoSlide'
import logo from '../assets/images/event/logo.png'
import logo2 from '../assets/images/event/logo2.png'
import color1 from '../assets/images/event/color1.png'
import color2 from '../assets/images/event/color2.png'
import color3 from '../assets/images/event/color3.png'
import color4 from '../assets/images/event/color4.png'
import color5 from '../assets/images/event/color5.png'
import color6 from '../assets/images/event/color6.png'
import event01 from '../assets/images/event/event-01.webp'
import nesto1 from '../assets/images/event/nesto-web-elements-01.webp'
import nesto2 from '../assets/images/event/nesto-web-elements-02.webp'
import nesto3 from '../assets/images/event/nesto-web-elements-03.webp'
import nesto4 from '../assets/images/event/nesto-web-elements-04.webp'
import nesto5 from '../assets/images/event/nesto-web-elements-05.webp'
import laban1 from '../assets/images/event/laban-web-elements-01.webp'
import laban2 from '../assets/images/event/laban-web-elements-02.webp'
import laban3 from '../assets/images/event/laban-web-elements-03.webp'
import laban4 from '../assets/images/event/laban-web-elements-04.webp'
import laban5 from '../assets/images/event/laban-web-elements-05.webp'
import FullImageSlide from '../gallery-slides/FullImageSlide'
import CarouselSlide from '../gallery-slides/CarouselSlide'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'

export default function EventMicrosite() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax
        image={headerImage}
        h1="Event"
        h1sub="Microsite"
        h2={['Logo', 'Graphic Design']}
        h2sub={['UI', 'Art Direction']}
      />

      <LogoSlide
        title="NESTOgether is a community-themed online event for Health Care Professionals."
        logo={logo}
        colors={[color1, color2, color3]}
      />

      <CarouselSlide items={[nesto1, nesto2, nesto3, nesto4, nesto5]} />

      <FullImageSlide image={event01} />

      <LogoSlide
        title="Lakbay Bayan is a proposed medical online event for Health Care Professionals."
        logo={logo2}
        colors={[color4, color5, color6]}
      />

      <CarouselSlide items={[laban1, laban2, laban3, laban4, laban5]} />

      <GalleryBottomNav leftNav="rogueDoughnuts" rightNav="askMara" />
    </GallerySlidePage>
  )
}
