export default function ItemsSlide({ items, className = '', noPadding = false }) {
  return (
    <div
      className={`slide gallery-slide gallery-row-slide row-mid-content ${className} ${
        !noPadding ? 'gpad-2x' : ''
      }`}
    >
      {items.map((image, idx) => (
        <div className="grs-item">
          <img key={idx} src={image} />
        </div>
      ))}
    </div>
  )
}
