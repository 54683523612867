import headerImage from '../assets/images/rogue/rogue-01.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import LogoSlide from '../gallery-slides/LogoSlide'
import logo from '../assets/images/rogue/logo.png'
import color1 from '../assets/images/rogue/color1.png'
import color2 from '../assets/images/rogue/color2.png'
import color3 from '../assets/images/rogue/color3.png'

import item01 from '../assets/images/rogue/rogue-03.webp'
import item02 from '../assets/images/rogue/rogue-04.webp'
import item06 from '../assets/images/rogue/rogue-06.webp'
import item07 from '../assets/images/rogue/rogue-07.webp'
import item08 from '../assets/images/rogue/rogue-08.webp'
import fullimage01 from '../assets/images/rogue/rogue-02.webp'
import webpage01 from '../assets/images/rogue/rogue-05.webp'
import carousel01 from '../assets/images/rogue/rogue-web-elements-01.webp'
import carousel02 from '../assets/images/rogue/rogue-web-elements-02.webp'
import carousel03 from '../assets/images/rogue/rogue-web-elements-03.webp'
import carousel04 from '../assets/images/rogue/rogue-web-elements-04.webp'
import FullImageSlide from '../gallery-slides/FullImageSlide'
import ItemsSlide from '../gallery-slides/ItemsSlide'
import WebpageSlide from '../gallery-slides/WebpageSlide'
import CarouselSlide from '../gallery-slides/CarouselSlide'
import ThreeItemsGrid from '../gallery-slides/ThreeItemsGrid'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'

export default function Rogue() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax
        image={headerImage}
        h1="Rogue"
        h1sub="Doughnuts"
        h2={['Brand Design', 'UI']}
      />

      <LogoSlide
        title={'Rogue is an artisinal sourdough doughnut made in the Philippines.'}
        logo={logo}
        colors={[color1, color2, color3]}
      />

      <FullImageSlide image={fullimage01} />
      <ItemsSlide items={[item01, item02]} />

      <WebpageSlide image={webpage01} />
      <CarouselSlide items={[carousel01, carousel02, carousel03, carousel04]} />
      <ThreeItemsGrid item1={item06} item2={item07} item3={item08} />

      <GalleryBottomNav rightNav="eventMicrosite" />
    </GallerySlidePage>
  )
}
