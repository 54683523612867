import './assets/v01.css'
import EventMicrosite from './pages/EventMicrosite'
import BBStudio from './pages/BBStudio'
import AskMara from './pages/AskMara'
import GraphicCollection from './pages/GraphicCollection'
import LogoCollection from './pages/LogoCollection'
import Rogue from './pages/Rogue'
import Home from './pages/Home'
import behance from './assets/images/socials-behance.png'
import dribbble from './assets/images/socials-dribbble.png'
import instagram from './assets/images/socials-instagram.png'
import { Routes, Route, Link, useLocation } from 'react-router-dom'

function App() {
  const { pathname } = useLocation()
  return (
    <div className="App">
      <div id="sticky-header">
        <div id="branding">
          <Link to={`/`}>Lex Galvan</Link>
        </div>
        <nav id="main-nav">
          <a href="/">Home</a>
          <a href="#contact">Contact</a>
        </nav>
      </div>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rogue-doughnuts" element={<Rogue />} />
        <Route path="/event-microsite" element={<EventMicrosite />} />
        <Route path="/bb-studio" element={<BBStudio />} />
        <Route path="/ask-mara" element={<AskMara />} />
        <Route path="/graphic-collection" element={<GraphicCollection />} />
        <Route path="/logo-collection" element={<LogoCollection />} />
      </Routes>

      <div id="contact" className={`slide full ${pathname !== '/' ? 'non-parallax' : ''}`}>
        <div id="hfo-content" className="full gpad col-mid-content">
          <div id="hfo-icontent">
            <h1>Let's work together.</h1>
            {/*<h2>Let's work<br/>together.</h2>*/}
            <h4><a href="mailto:contact@lexgalvan.com">contact@lexgalvan.com</a></h4>
            <div id="hfo-socials">
              <a href="https://www.behance.net/lxg07" target="_blank" rel="noreferrer">
                <img src={behance} />
              </a>
              <a href="https://dribbble.com/lxg07" target="_blank" rel="noreferrer">
                <img src={dribbble} />
              </a>
              <a href="https://instagram.com/lxg07/" target="_blank" rel="noreferrer">
                <img src={instagram} />
              </a>
            </div>
          </div>

        </div>
        {/*<div id="hfo-triangle"></div>*/}
        {/*<div id="hfo-icon"></div>*/}
      </div>
    </div>
  )
}

export default App
