import headerImage from '../assets/images/logo/logo-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import item01 from '../assets/images/logo/actionstack.png'
import item02 from '../assets/images/logo/bright63.png'
import item03 from '../assets/images/logo/sociallight.png'
import item04 from '../assets/images/logo/outrunmnl.png'
import item05 from '../assets/images/logo/eso.png'
import item06 from '../assets/images/logo/qrpay.png'
import fullimage01 from '../assets/images/logo/logo-01.webp'
import fullimage02 from '../assets/images/logo/logo-02.webp'
import fullimage03 from '../assets/images/logo/logo-03.webp'
import fullimage04 from '../assets/images/logo/logo-04.png'
import FullImageSlide from '../gallery-slides/FullImageSlide'
import ItemsSlide from '../gallery-slides/ItemsSlide'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'

export default function LogoCollection() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax image={headerImage} h1="Logo" h1sub="Collection" />

      <ItemsSlide items={[item01, item02, item03]} className="logocol-items full" />

      <FullImageSlide image={fullimage01} />
      <FullImageSlide
        image={fullimage04}
        className="gallery-accent full logocol-carnival row-mid-content gpad"
      />

      <ItemsSlide items={[item04, item05, item06]} className="logocol-items full" />

      <FullImageSlide image={fullimage02} />
      <FullImageSlide image={fullimage03} />

      <GalleryBottomNav leftNav="graphicCollection" />
    </GallerySlidePage>
  )
}
