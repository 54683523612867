import headerImage from '../assets/images/askmara/askmara-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import LogoSlide from '../gallery-slides/LogoSlide'
import logo from '../assets/images/askmara/logo.png'
import color1 from '../assets/images/askmara/color1.png'
import color2 from '../assets/images/askmara/color2.png'
import color3 from '../assets/images/askmara/color3.png'
import horizontal01 from '../assets/images/askmara/askmara-web-elements-01.webp'
import horizontal02 from '../assets/images/askmara/askmara-web-elements-02.webp'
import horizontal03 from '../assets/images/askmara/askmara-web-elements-03.webp'
import horizontal04 from '../assets/images/askmara/askmara-web-elements-04.webp'
import item01 from '../assets/images/askmara/askmara2-web-elements-01.webp'
import item02 from '../assets/images/askmara/askmara2-web-elements-02.webp'
import fullimage01 from '../assets/images/askmara/askmara-01.webp'

import FullImageSlide from '../gallery-slides/FullImageSlide'
import HorizontalSlide from '../gallery-slides/HorizontalSlide'
import ItemsSlide from '../gallery-slides/ItemsSlide'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'

export default function AskMara() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax image={headerImage} h1="AskMara" h2={['Graphic Design', 'Logo', 'UI']} />

      <LogoSlide
        title={[
          <span key={1}>
            Ask Mara is your go-to-gal for contraception! She is here to empower women to make
            informed choices about contraception, reproductive health and women’s health topics.
          </span>,
        ]}
        logo={logo}
        colors={[color1, color2, color3]}
      />

      <FullImageSlide image={fullimage01} />

      <HorizontalSlide items={[horizontal01, horizontal02, horizontal03, horizontal04]} />

      <ItemsSlide items={[item01, item02]} className="askmara-items" />

      <GalleryBottomNav leftNav="eventMicrosite" rightNav="bbStudio" />
    </GallerySlidePage>
  )
}
