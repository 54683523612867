import headerImage from '../assets/images/logo/logo-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import rogueDoughnuts from '../assets/images/home-01.webp'
import eventMicrosite from '../assets/images/home-02.webp'
import askMara from '../assets/images/home-03.webp'
import bbStudio from '../assets/images/home-04.webp'
import graphicCollection from '../assets/images/home-05.webp'
import logoCollection from '../assets/images/home-06.webp'
import HomeNavSlide from '../gallery-slides/HomeNavSlide'

export default function Home() {
  return (
    <GallerySlidePage id="home-page">
      <div id="home-branding-overlay" className="full gpad"></div>

      <div className="slide full gpad col-mid-content">
        <h1>
          Graphic
          <br />
          Designer<span className="home-plus">+</span>
          <br />
          Art Director
        </h1>
      </div>

      <HomeNavSlide key="rogue" link="/rogue-doughnuts" image={rogueDoughnuts}>
        Rogue
        <br />
        Doughnuts
      </HomeNavSlide>

      <HomeNavSlide key="event-microsite" link="/event-microsite" image={eventMicrosite}>
        Event
        <br />
        Microsite
      </HomeNavSlide>

      <HomeNavSlide key="ask-mara" link="/ask-mara" image={askMara}>
        AskMara
      </HomeNavSlide>

      <HomeNavSlide key="bb-studio" link="/bb-studio" image={bbStudio}>
        B+B Studio
        <br />
        Manila
      </HomeNavSlide>

      <HomeNavSlide key="graphic-collection" link="/graphic-collection" image={graphicCollection}>
        Graphic
        <br />
        Collection
      </HomeNavSlide>

      <HomeNavSlide key="logo-collection" link="/logo-collection" image={logoCollection}>
        Logo
        <br />
        Collection
      </HomeNavSlide>
    </GallerySlidePage>
  )
}
