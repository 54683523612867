export default function ThreeItemsGrid({ item1, item2, item3 }) {
  return (
    <div className="slide gallery-slide gpad row-mid-content grs-outer">
      <div className="grs-2-3">
        <img src={item1} />
      </div>
      <div className="col-mid-content grs-1-3">
        <img src={item2} />
        <img src={item3} />
      </div>
    </div>
  )
}
