import rogueDoughnuts from '../assets/images/home-01.webp'
import eventMicrosite from '../assets/images/home-02.webp'
import askMara from '../assets/images/home-03.webp'
import bbStudio from '../assets/images/home-04.webp'
import graphicCollection from '../assets/images/home-05.webp'
import logoCollection from '../assets/images/home-06.webp'
import prevArrow from '../assets/images/prev_arrow.png'
import nextArrow from '../assets/images/next_arrow.png'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

export default function GalleryBottomNav({leftNav, rightNav}) {
  let navigate = useNavigate()
  const [hoverImg, setHoverImg] = useState('')
  const [leftProjName, setLeftProjName] = useState('')
  const [leftProjLink, setLeftProjLink] = useState('#')
  const [rightProjName, setRightProjName] = useState('')
  const [rightProjLink, setRightProjLink] = useState('#')

  function setProjectMeta(leftNav, rightNav) {
    const titles = {
      rogueDoughnuts: 'Rogue Doughnuts',
      eventMicrosite: 'Event Microsite',
      askMara: 'Ask Mara',
      bbStudio: 'B+B Studio Manila',
      graphicCollection: 'Graphic Collection',
      logoCollection: 'Logo Collection',
    }

    const links = {
      rogueDoughnuts: '/rogue-doughnuts',
      eventMicrosite: '/event-microsite',
      askMara: '/ask-mara',
      bbStudio: '/bb-studio',
      graphicCollection: '/graphic-collection',
      logoCollection: '/logo-collection',
    }

    if (leftNav) {
      setLeftProjName(titles[leftNav])
      setLeftProjLink(links[leftNav])
    }

    if (rightNav) {
      setRightProjName(titles[rightNav])
      setRightProjLink(links[rightNav])
    }
  }

  const ProjectImage = () => {
    switch (hoverImg) {
      case 'rogueDoughnuts':
        return <img src={rogueDoughnuts}/>
      case 'eventMicrosite':
        return <img src={eventMicrosite}/>
      case 'askMara':
        return <img src={askMara}/>
      case 'bbStudio':
        return <img src={bbStudio}/>
      case 'graphicCollection':
        return <img src={graphicCollection}/>
      case 'logoCollection':
        return <img src={logoCollection}/>
      default:
        return ''
    }
  }

  useEffect(() => setProjectMeta(leftNav, rightNav), [leftNav, rightNav])

  return (
    <div className="slide gallery-slide gpad-2x gallery-bottom-nav">
      <div className="gbn-wrapper">
        {leftNav && (
          <>
            <div className="gbn-title">{leftProjName}</div>
            <div className="gbn-dir">
              <span
                onMouseEnter={() => {
                  setHoverImg(leftNav)
                }}
                onMouseLeave={() => {
                  setHoverImg('')
                }}
                onClick={() => {
                  navigate(leftProjLink)
                }}
              >
                PREV
              </span>
            </div>
            <div className="gbn-arrow" onClick={() => {
              navigate(leftProjLink)
            }}><img src={prevArrow}/></div>
          </>
        )}
      </div>
      <div className="gbn-wrapper row-mid-content">
        <div className="gbn-proj-image">
          <ProjectImage/>
        </div>
      </div>
      <div className="gbn-wrapper gbn-wrapper-right">
        {rightNav && (
          <>
            <div className="gbn-title">{rightProjName}</div>
            <div className="gbn-dir">
              <span
                onMouseEnter={() => {
                  setHoverImg(rightNav)
                }}
                onMouseLeave={() => {
                  setHoverImg('')
                }}
                onClick={() => {
                  navigate(rightProjLink)
                }}
              >
                NEXT
              </span>
            </div>
            <div className="gbn-arrow" onClick={() => {
              navigate(rightProjLink)
            }}>
              <img src={nextArrow}/>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
