export default function LogoSlide({ logo, colors, title }) {
  return (
    <div className="slide full gallery-slide row-mid-content">
      <div className="gallery-brand col-mid-content col-align-center">
        <img className="gb-logo" src={logo} />
        {/*<div className="gb-colors">*/}
        {/*  {colors.map((item, idx) => (*/}
        {/*    <img src={item} key={idx} />*/}
        {/*  ))}*/}
        {/*</div>*/}
        <div className="font-light prose text-center px-1">{title}</div>
      </div>
    </div>
  )
}
