import bar from '../assets/images/bar.png'
import prev from '../assets/images/gs-prev.png'
import prevDisabled from '../assets/images/gs-prev-disabled.png'
import next from '../assets/images/gs-next.png'
import nextDisabled from '../assets/images/gs-next-disabled.png'
import Slider from 'react-slick'
import { useRef, useState } from 'react'

export default function CarouselSlide({ items, noBar = false }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    lazyLoad: true,
    afterChange(index) {
      setCurrPage(index + 1)
    },
  }
  const carousel = useRef(null)
  const [currPage, setCurrPage] = useState(1)
  return (
    <div className="slide full gallery-slide gallery-slider row-mid-content">
      <div className="container">
        {!noBar && (
          <div className="gallery-slider-bar">
            <img src={bar} />
          </div>
        )}
        <div className="gallery-slider-pages">
          <Slider {...settings} ref={carousel}>
            {items.map((item, idx) => (
              <img src={item} key={idx} />
            ))}
          </Slider>
        </div>
        <div className="gallery-slider-controls">
          <div className="gsc-pages">
            {currPage}/{items.length}
          </div>
          <img
            src={currPage === 1 ? prevDisabled : prev}
            onClick={() => {
              carousel.current.slickPrev()
              // if (currPage > 1) {
              //   setCurrPage(currPage - 1)
              // }
            }}
          />
          <img
            src={currPage === items.length ? nextDisabled : next}
            onClick={() => {
              carousel.current.slickNext()
              // if (currPage < items.length) {
              //   setCurrPage(currPage + 1)
              // }
            }}
          />
        </div>
      </div>
    </div>
  )
}
