import bar from '../assets/images/bar.png'
import prev from '../assets/images/gs-prev.png'
import next from '../assets/images/gs-next.png'
import useDragToScroll from '../hooks/useDragToScroll'
import { useRef } from 'react'

export default function HorizontalSlide({ items }) {
  const ref = useRef(null)

  const { mouseDownHandler } = useDragToScroll(ref)
  return (
    <div className="slide full gallery-slide horizontal-slider row-mid-content gallery-accent">
      <div className="hs-container" ref={ref} onMouseDown={mouseDownHandler}>
        <div className="hs-scroller">
          {items.map((item, idx) => (
            <img src={item} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}
