import headerImage from '../assets/images/graphic/graphic-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import carousel01 from '../assets/images/graphic/graphics-web-elements-01.webp'
import carousel02 from '../assets/images/graphic/graphics-web-elements-02.webp'
import carousel03 from '../assets/images/graphic/graphics-web-elements-03.webp'
import carousel04 from '../assets/images/graphic/graphics-web-elements-04.webp'
import carousel05 from '../assets/images/graphic/graphics-web-elements-05.webp'
import carousel06 from '../assets/images/graphic/graphics-web-elements-06.webp'
import carousel07 from '../assets/images/graphic/graphics-web-elements-07.webp'
import carousel08 from '../assets/images/graphic/graphics-web-elements-08.webp'
import carousel09 from '../assets/images/graphic/graphics-web-elements-09.webp'
import carousel10 from '../assets/images/graphic/graphics-web-elements-10.webp'
import item01 from '../assets/images/graphic/graphic-01.webp'
import item02 from '../assets/images/graphic/graphic-02.webp'
import fullimage01 from '../assets/images/graphic/graphic-03.webp'
import FullImageSlide from '../gallery-slides/FullImageSlide'
import ItemsSlide from '../gallery-slides/ItemsSlide'
import CarouselSlide from '../gallery-slides/CarouselSlide'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'
import VideoSlide from '../gallery-slides/VideoSlide'

export default function GraphicCollection() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax image={headerImage} h1="Graphic" h1sub="Collection" />

      <CarouselSlide items={[carousel01, carousel02, carousel03, carousel04, carousel05]} noBar />

      <ItemsSlide items={[item01, item02]} className="no-gap" noPadding />
      {/*<ItemsSlide items={[item03]} className="graphic-items full" />*/}
      <VideoSlide caption="A 360-degree experience job post for Amplify." />
      <FullImageSlide image={fullimage01} />
      <CarouselSlide items={[carousel06, carousel07, carousel08, carousel09, carousel10]} noBar />

      <GalleryBottomNav leftNav="bbStudio" rightNav="logoCollection" />
    </GallerySlidePage>
  )
}
