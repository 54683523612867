import headerImage from '../assets/images/bbstudio/bbstudio-00.webp'
import HeaderParallax from '../gallery-slides/HeaderParallax'
import GallerySlidePage from '../gallery-slides/GallerySlidePage'
import LogoSlide from '../gallery-slides/LogoSlide'
import logo from '../assets/images/bbstudio/logo.png'
import color1 from '../assets/images/bbstudio/color1.png'
import color2 from '../assets/images/bbstudio/color2.png'
import color3 from '../assets/images/bbstudio/color3.png'
import carousel01 from '../assets/images/bbstudio/bbstudio-web-elements-01.webp'
import carousel02 from '../assets/images/bbstudio/bbstudio-web-elements-02.webp'
import carousel03 from '../assets/images/bbstudio/bbstudio-web-elements-03.webp'
import carousel04 from '../assets/images/bbstudio/bbstudio-web-elements-04.webp'
import carousel05 from '../assets/images/bbstudio/bbstudio-web-elements-05.webp'
import bbstudio1 from '../assets/images/bbstudio/bbstudio-01.webp'
import bbstudio2 from '../assets/images/bbstudio/bbstudio-02.webp'
import CarouselSlide from '../gallery-slides/CarouselSlide'
import WebpageSlide from '../gallery-slides/WebpageSlide'
import FullImageSlide from '../gallery-slides/FullImageSlide'
import GalleryBottomNav from '../gallery-slides/GalleryBottomNav'

export default function BBStudio() {
  return (
    <GallerySlidePage id="event-page">
      <HeaderParallax
        image={headerImage}
        h1="B+B Studio"
        h1sub="Manila"
        h2={['Graphic Design', 'UI']}
      />

      <LogoSlide
        title="B+B Studio Manila is Philippine's first, largest & most equipped Stott Pilates and Redcord Suspension studio."
        logo={logo}
        colors={[color1, color2, color3]}
      />

      <WebpageSlide image={bbstudio1} />

      <CarouselSlide items={[carousel01, carousel02, carousel03, carousel04, carousel05]} />

      <FullImageSlide image={bbstudio2} />

      <GalleryBottomNav leftNav="askMara" rightNav="graphicCollection" />
    </GallerySlidePage>
  )
}
