export default function VideoSlide({ caption = '', className = '' }) {
  return (
    <div className={`slide gallery-slide gallery-accent gpad-2x row-mid-content ${className}`}>
      <div className="container">
        <div style={{
          padding: "90% 0 0 0",
          position: "relative",
        }}>
          <iframe
            src="https://player.vimeo.com/video/714416212?h=27c169a5cf&loop=1&title=0&byline=0&portrait=0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen>

          </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        {caption.length > 0 && (
          <div className="font-light text-center px-1 py-1">{caption}</div>
        )}
      </div>
    </div>
  )
}
