export default function useDragToScroll(ref) {
  let pos = { top: 0, left: 0, x: 0, y: 0 }

  const mouseDownHandler = (event) => {
    if (ref.current) {
      pos = {
        // The current scroll
        left: ref.current.scrollLeft,
        top: ref.current.scrollTop,
        // Get the current mouse position
        x: event.clientX,
        y: event.clientY,
      }

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }
  }

  const mouseMoveHandler = (event) => {
    if (ref.current) {
      // How far the mouse has been moved
      const dx = event.clientX - pos.x
      const dy = event.clientY - pos.y

      // Scroll the element
      ref.current.scrollTop = pos.top - dy
      ref.current.scrollLeft = pos.left - dx
    }
  }

  const mouseUpHandler = () => {
    if (ref.current) {
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }
  }

  return { mouseDownHandler }
}
