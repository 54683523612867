function unpackSubtitles(subtitles) {
  let jsx = []
  for (let item in subtitles) {
    jsx.push(<span key={item}>{subtitles[item]}</span>)
    if (parseInt(item) + 1 < subtitles.length) {
      jsx.push(' | ')
    }
  }
  return jsx
}

export default function HeaderParallax({ image, h1, h1sub, h2, h2sub }) {
  return (
    <>
      <div
        className="slide full parallax-bg"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <div className="slide full">
        <div className="gallery-header">
          <div className="gh-meta full row-mid-content">
            <div className="gallery-header-content">
              <h1>
                {h1}
                {h1sub && (
                  <>
                    <br /> {h1sub}
                  </>
                )}
              </h1>
              <h2>{unpackSubtitles(h2)}</h2>
              {h2sub && <h2 className="no-margin">{unpackSubtitles(h2sub)}</h2>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
