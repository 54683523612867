import { Link, useNavigate } from 'react-router-dom'
import arrow from '../assets/images/arrow.png'

export default function HomeNavSlide({ children, image, link }) {
  const navigate = useNavigate()

  return (
    <div className="slide full gpad row-mid-content">
      <div className="slide-inner">
        <img
          src={image}
          onClick={() => {
            navigate(link)
          }}
        />
        <div
          className="slide-meta"
          onClick={() => {
            navigate(link)
          }}
        >
          <h1>{children}</h1>
          <h4 className="nav-to-page" data-href="rogue">
            View{' '}
            <span className="slide-arrow">
              <img src={arrow} alt="Arrow" />
            </span>
          </h4>
        </div>
      </div>
    </div>
  )
}
